import { useMutation } from '@apollo/client';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { SportsBasketball } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../queries';
import DisplayAlert from '../SignUp/DisplayAlert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.brewdoglabs.com/">
        Brewdog Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(https://www.leaguewarrior.com/247ksuf.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'blue'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const SignInForm = ({ token, setToken, setUser, notifyWith }) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');

  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const history = useHistory();
  const [login, result] = useMutation(LOGIN, {
    onError: (error) => {
      notifyWith(getHumanError(error.message));
    }
  });
  function getHumanError(str) {
    return str.split(':')[1];
  }
  useEffect(() => {
    if (result.data) {
      const token = result.data.logIn.token;
      const user = result.data.logIn.user;
      const firstName = user.firstName;
      setToken(token);
      setUser(user);
      localStorage.setItem('user-token', token);
      localStorage.setItem('user-name', firstName);
      localStorage.setItem('user-id', result.data.logIn.user.id);
      history.push('/dashboard');
    }
  }, [history, result.data, setToken]);

  if (token) {
    return null;
  }

  const submit = (event) => {
    event.preventDefault();
    localStorage.clear();
    login({
      variables: {
        username: username,
        password: password
      }
    });

    // notifyWith(`${username} succeeded log in`);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SportsBasketball />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              type="username"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }} //whenever the text field change, you save the value in state
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => {
                setPassword(event.target.value);
              }} //whenever the text field change, you save the value in state
            />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};
export default SignInForm;
