import React, {useState} from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import { SingleEliminationBracket, DoubleEliminationBracket, Match, SVGViewer } from '@g-loot/react-tournament-brackets';
import {DropdownButton, Dropdown} from 'react-bootstrap';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  button: {
    color: 'gray'
  }
}));

export default function Playoffs() {
  const classes = useStyles();
  const [bracket,setBracket]=useState('4A');
  const [open, setOpen] = React.useState(false);
  const url4a='https://www.leaguewarrior.com/4aa2024.png';
  const url3a='https://www.leaguewarrior.com/3aa2024.png';
  const url2a='https://www.leaguewarrior.com/2aaa2024.png';
  const url1a='https://www.leaguewarrior.com/1aa2024.png';


  const handleSelect=(e)=>{

    if(e==='4A') {
      setValue(url4a);
    }
    if(e==='3A') {
      setValue(url3a);
    }
    if(e==='2A') {
      setValue(url2a);
    }
    if(e==='1A') {
      setValue(url1a);
    }

    setBracket(e);

  };

  const matches= [

    {
      id: 100,
      nextMatchId: 102,
      tournamentRoundText: '1',
      startTime: '2023-05-25 7:40PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'rub',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '1. Rollin Up Bunts'
        },
        {
          id: 'lo',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '4. Lights Out'
        }
      ]
    },
    {
      id: 101,
      nextMatchId: 102,
      tournamentRoundText: '1',
      startTime: '2023-05-25 6:50PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'db',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '2. Das Boot'
        },
        {
          id: 'wmj',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '3. Waiting On Mike Jones'
        }
      ]
    },

    {
      id: 102,
      nextMatchId: null,
      tournamentRoundText: '2',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'dreamball',
          resultText: null,
          isWinner: null,
          status: null,
          name: 'TBD'
        },
        {
          id: 'dasboot',
          resultText: null,
          isWinner: null,
          status: null,
          name: 'TBD'
        }
      ]
    }
  ];



  const matches3a=[

    {
      id: 100,
      nextMatchId: 111,
      tournamentRoundText: '1',
      startTime: '2023-05-25 9:20PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'kicktease',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '1. Kick Tease'
        },
        {
          id: 'lo',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '8. Rapp it and Tapp it'
        }
      ]
    },


    {
      id: 103,
      nextMatchId: 112,
      tournamentRoundText: '1',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'fwb',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '3. Friends With Benefits'
        },
        {
          id: 'redrum',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '6. redruM'
        }
      ]
    },

    {
      id: 104,
      nextMatchId: 111,
      tournamentRoundText: '1',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
        {
          id: '1d3b',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '4. 1st Date - 3rd Base'
        },
        {
          id: 'nutshots',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '5. The Nutshots'
        }
      ]
    },
    {
      id: 102,
      nextMatchId: 112,
      tournamentRoundText: '1',
      startTime: '2023-05-25 9:20PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'somb',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '2. Sit On My Base'
        },
        {
          id: 'wmj',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '7. Katie\'s Back'
        }
      ]
    },
    {
      id: 111,
      nextMatchId: 113,
      tournamentRoundText: '2',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 112,
      nextMatchId: 113,
      tournamentRoundText: '2',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },

    {
      id: 113,
      nextMatchId: null,
      tournamentRoundText: '3',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    }
  ];

  const matches3ax= [
    {
      id: 19753,
      nextMatchId: 44,
      tournamentRoundText: '3',
      startTime: '10/27/2022 08:30 PM Field 4',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'pitchplease',
          resultText: null,
          isWinner: false,
          status: null,
          name: '1. Pitch Please'
        },
        {
          id: 'redrum',
          resultText: null,
          isWinner: false,
          status: null,
          name: '4. redruM'
        }
      ]
    },
    {
      id: 19754,
      nextMatchId: 19753,
      tournamentRoundText: '2',
      startTime: '2022-10-20 6:50PM',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'pitchplease',
          resultText: "7",
          isWinner: true,
          status: "PLAYED",
          name: '1. Pitch Please'
        },
        {
          id: 'ballz',
          resultText: "5",
          isWinner: false,
          status: "PLAYED",
          name: '9. Ballz'
        }
      ]
    },
    {
      id: 3,
      nextMatchId: 19753,
      tournamentRoundText: '2',
      startTime: '2022-10-20 6:50PM',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'sitonmybase',
          resultText: "3",
          isWinner: false,
          status: "PLAYED",
          name: '5. Sit On My Base'
        },
        {
          id: 'redrum',
          resultText: "6",
          isWinner: true,
          status: "PLAYED",
          name: '4. redruM'
        }
      ]
    },
    {
      id: 19758,
      nextMatchId: 19754,
      tournamentRoundText: '1',
      startTime: '2022-10-20 6:00PM',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'lara',
          resultText: "6",
          isWinner: false,
          status: "PLAYED",
          name: '8. Lara Books Goon Squad'
        },
        {
          id: 'ballz',
          resultText: "8",
          isWinner: true,
          status: "PLAYED",
          name: '9. Ballz'
        }
      ]
    },
    {
      id: 1,
      nextMatchId: 3,
      tournamentRoundText: '1',
      startTime: '2022-10-20',
      state: 'DONE',
      participants: [

      ]
    },
    {
      id: 2,
      nextMatchId: 3,
      tournamentRoundText: '1',
      startTime: '2022-10-20',
      state: 'DONE',
      participants: [

      ]
    },
    {
      id: 1,
      nextMatchId: 19754,
      tournamentRoundText: '1',
      startTime: '2022-10-20',
      state: 'DONE',
      participants: [
      ]
    },


    {
      id: 44,
      nextMatchId: null,
      tournamentRoundText: '4',
      startTime: '10/27/2022 09:20 PM Field 4',
      state: 'SCHEDULED',
      participants: []
    },



    {
      id: 33,
      nextMatchId: 44,
      tournamentRoundText: '3',
      startTime: '10/27/2022 08:30 PM Field 3',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'misfits',
          resultText: null,
          isWinner: false,
          status: null,
          name: '3. Misfits'
        },
        {
          id: 'fwb',
          resultText: null,
          isWinner: false,
          status: null,
          name: '7. Friends With Benefits'
        }
      ]
    },
    {
      id: 21,
      nextMatchId: 33,
      tournamentRoundText: '2',
      startTime: '2022-10-20 6:50PM',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'misfits',
          resultText: "12",
          isWinner: true,
          status: "PLAYED",
          name: '3. Misfits'
        },
        {
          id: 'nut',
          resultText: "9",
          isWinner: false,
          status: "PLAYED",
          name: '6. The Nutshots'
        }
      ]
    },
    {
      id: 22,
      nextMatchId: 33,
      tournamentRoundText: '2',
      startTime: '2022-10-20 6:50PM',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'ohana',
          resultText: "1",
          isWinner: false,
          status: null,
          name: '2. Ohana Balls'
        },
        {
          id: 'fwb',
          resultText: "6",
          isWinner: true,
          status: "PLAYED",
          name: '7. Friends With Benefits'
        }
      ]
    },
    {
      id: 13,
      nextMatchId: 22,
      tournamentRoundText: '1',
      startTime: '2022-10-20 6:00PM',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'fwb',
          resultText: "10",
          isWinner: true,
          status: "PLAYED",
          name: '7. Friends With Benefits'
        },
        {
          id: 'sloppy',
          resultText: "0",
          isWinner: false,
          status: "PLAYED",
          name: '10. Sloppy Seconds'
        }
      ]
    },
    {
      id: 12,
      nextMatchId: 21,
      tournamentRoundText: '1',
      startTime: '2022-10-20 6:00PM',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'nut',
          resultText: "9",
          isWinner: true,
          status: "PLAYED",
          name: '6. The Nutshots'
        },
        {
          id: 'yayki',
          resultText: "2",
          isWinner: false,
          status: "PLAYED",
          name: '11. YAY! Kickball'
        }
      ]
    },
    {
      id: 111,
      nextMatchId: 21,
      tournamentRoundText: '1',
      startTime: '2022-10-20',
      state: 'DONE',
      participants: [
      ]
    },
    {
      id: 112,
      nextMatchId: 22,
      tournamentRoundText: '1',
      startTime: '2022-10-20',
      state: 'DONE',
      participants: [
      ]
    }
  ];

  const matches2a=[

    {
      id: 1,
      nextMatchId: 11,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },

    {
      id: 3,
      nextMatchId: 12,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 4,
      nextMatchId: 12,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 5,
      nextMatchId: 13,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 2,
      nextMatchId: 13,
      tournamentRoundText: '1',
      startTime: '2023-05-25 7:40PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'yay',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '7. YAY! Kickball'
        },
        {
          id: 'wives',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '10. Trophy Wives'
        }
      ]
    },
    {
      id: 6,
      nextMatchId: 11,
      tournamentRoundText: '1',
      startTime: '2023-05-25 7:40PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'vibe',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '8. Pitch Don\'t Kick My Vibe'
        },
        {
          id: 'ballsdeep',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '9. Balls Deep'
        }
      ]
    },
    {
      id: 7,
      nextMatchId: 14,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 8,
      nextMatchId: 14,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },

    {
      id: 11,
      nextMatchId: 21,
      tournamentRoundText: '2',
      startTime: '2023-05-25 8:30PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'uglies',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '1. Bunting Uglies'
        }
      ]
    },
    {
      id: 12,
      nextMatchId: 22,
      tournamentRoundText: '2',
      startTime: '2023-05-25 6:00PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'jff',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '3. Just for Fun'
        },
        {
          id: 'kiqi',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '6. Kick It and Quit It'
        }
       ]
    },
    {
      id: 14,
      nextMatchId: 21,
      tournamentRoundText: '2',
      startTime: '2023-05-25 8:30PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'srr',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '4. Susquehanna River Rocks'
        },
        {
          id: 'ballz',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '5. Ballz'
        }
      ]
    },
    {
      id: 13,
      nextMatchId: 22,
      tournamentRoundText: '2',
      startTime: '2023-05-25 8:30PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'uglies',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '2. Sloppy Seconds'
        }
      ]
    },


    {
      id: 21,
      nextMatchId: 31,
      tournamentRoundText: '3',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 22,
      nextMatchId: 31,
      tournamentRoundText: '3',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 31,
      nextMatchId: null,
      tournamentRoundText: '4',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    }



  ];


  const matches1a=[

    {
      id: 101,
      nextMatchId: 1,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },

    {
      id: 102,
      nextMatchId: 1,
      tournamentRoundText: '1',
      startTime: '2023-05-25 6:00PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'srr',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '15. Let The Liquor Do the Kicking'
        },
        {
          id: 'ballz',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '18. Internal Balls'
        }
      ]
    },

    {
      id: 103,
      nextMatchId: 2,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 104,
      nextMatchId: 2,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 105,
      nextMatchId: 3,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 106,
      nextMatchId: 3,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 107,
      nextMatchId:4,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 108,
      nextMatchId: 4,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 109,
      nextMatchId: 5,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 110,
      nextMatchId: 5,
      tournamentRoundText: '1',
      startTime: '2023-05-25 6:00PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'busters',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '16. Rubber Busters'
        },
        {
          id: 'ifubunt',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '17. If You Bunt It They Will Come'
        }
      ]
    },
    {
      id: 111,
      nextMatchId: 6,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [

      ]
    },
    {
      id: 112,
      nextMatchId: 6,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 113,
      nextMatchId: 7,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 114,
      nextMatchId: 7,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 115,
      nextMatchId: 8,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },
    {
      id: 116,
      nextMatchId: 8,
      tournamentRoundText: '1',
      startTime: '2023-05-25',
      state: 'WALK_OVER',
      participants: [
      ]
    },


    {
      id: 1,
      nextMatchId: 11,
      tournamentRoundText: '2',
      startTime: '2023-05-25 6:50PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'bangarang',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '1. Bangarang'
        }
      ]
    },
    {
      id: 2,
      nextMatchId: 11,
      tournamentRoundText: '2',
      startTime: '2023-05-25 8:30PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'dragon',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '8. Dragon My Ballz'
        },
        {
          id: '10',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '9. 10-10'
        }
      ]
    },
    {
      id: 3,
      nextMatchId: 12,
      tournamentRoundText: '2',
      startTime: '2023-05-25 9:20PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'lickbunt',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '4. Lick My Bunt'
        },
        {
          id: 'avg',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '13. Aggressively Average'
        }
      ]
    },
    {
      id: 4,
      nextMatchId: 12,
      tournamentRoundText: '2',
      startTime: '2023-05-25 6:50PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'mech',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '5. Mechanicsburg Kickball Team'
        },
        {
          id: 'b',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '12. Plan B Team'
        }
      ]
    },
    {
      id: 5,
      nextMatchId: 13,
      tournamentRoundText: '2',
      startTime: '2023-05-25 6:50PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'winlose',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '2. Win Or Looze We Still Booze'
        }
      ]
    },
    {
      id: 6,
      nextMatchId: 13,
      tournamentRoundText: '2',
      startTime: '2023-05-25 7:40PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'forrest',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '7. Bunt, Forrest, Bunt!'
        },
        {
          id: 'enola',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '10. Enola Dragons'
        }
      ]
    },
    {
      id: 7,
      nextMatchId: 14,
      tournamentRoundText: '2',
      startTime: '2023-05-25 9:20PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'tequila',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '3. Tequila Mockingbird'
        },
        {
          id: 'mike',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '14. Magic Mike\'s Ballers'
        }
      ]
    },
    {
      id: 8,
      nextMatchId: 14,
      tournamentRoundText: '2',
      startTime: '2023-05-25 6:00PM',
      state: 'SCHEDULED',
      participants: [
        {
          id: 'onekick',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '6. One Kick Wonders'
        },
        {
          id: 'melon',
          resultText: "",
          isWinner: null,
          status: "SCHEDULED",
          name: '11. Humongous Melonheads'
        }
      ]
    },

    {
      id: 11,
      nextMatchId: 21,
      tournamentRoundText: '3',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 12,
      nextMatchId: 21,
      tournamentRoundText: '3',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 13,
      nextMatchId: 22,
      tournamentRoundText: '3',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 14,
      nextMatchId: 22,
      tournamentRoundText: '3',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },

    {
      id: 21,
      nextMatchId: 31,
      tournamentRoundText: '4',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 22,
      nextMatchId: 31,
      tournamentRoundText: '4',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    },
    {
      id: 31,
      nextMatchId: null,
      tournamentRoundText: '5',
      startTime: '2023-06-01',
      state: 'SCHEDULED',
      participants: [
      ]
    }
  ];

  const [value,setValue]=useState(url4a);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setBracket(event.target.value);

    if(bracket==='4A') {
      setValue(url4a);
      console.log(event.target.value);
    }
    if(bracket==='3A') {
      setValue(url3a);
      console.log(event.target.value);
    }
    if(bracket==='2A') {
      setValue(url2a);
      console.log(event.target.value);
    }
    if(bracket==='1A') {
      setValue(url1a);
      console.log(event.target.value);
    }
  };

  return (
    <div>
      {/*<Grid container direction="column" justifyContent="center" alignItems="center">*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography>4A Playoff Bracket</Typography>*/}
      {/*    <img src="/4a-bracket-upd.svg" alt="" />*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography>2A Playoff Bracket</Typography>*/}
      {/*    <img src="/2a-bracket-upd.svg" alt="" />*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography>1A-Super Playoff Bracket</Typography>*/}
      {/*    <img src="/1a-super-bracket.svg" alt="" />*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography>1A Playoff Bracket</Typography>*/}
      {/*    <img src="/1a-bracket-upd.svg" alt="" />*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

      {/*<SingleEliminationBracket*/}
      {/*  matches={matches}*/}
      {/*  matchComponent={Match}*/}
      {/*  // svgWrapper={({ children, ...props }) => (*/}
      {/*  //   <SVGViewer width={1500} height={1500} {...props}>*/}
      {/*  //     {children}*/}
      {/*  //   </SVGViewer>*/}
      {/*  // )}*/}
      {/*/>*/}
      <br/>
      <DropdownButton
        title="Brackets"
        onSelect={handleSelect}
        variant='secondary'
      >

        <Dropdown.Item eventKey="4A">4A</Dropdown.Item>
        <Dropdown.Item eventKey="3A">3A</Dropdown.Item>
        <Dropdown.Item eventKey="2A">2A</Dropdown.Item>
        <Dropdown.Item eventKey="1A">1A</Dropdown.Item>

      </DropdownButton>



      {/*<Button onClick={handleOpen}>*/}
      {/*  Select Bracket*/}
      {/*</Button>*/}
      {/*<FormControl >*/}
      {/*  <InputLabel id="demo-controlled-open-select-label">Division</InputLabel>*/}
      {/*  <Select*/}
      {/*    labelId="demo-controlled-open-select-label"*/}
      {/*    id="demo-controlled-open-select"*/}
      {/*    open={open}*/}
      {/*    onClose={handleClose}*/}
      {/*    onOpen={handleOpen}*/}
      {/*    value={bracket}*/}
      {/*    label="Age"*/}
      {/*    onChange={handleChange}*/}
      {/*  >*/}
      {/*    <MenuItem value={'4A'}>4A</MenuItem>*/}
      {/*    <MenuItem value={'3A'}>3A</MenuItem>*/}
      {/*    <MenuItem value={'2A'}>2A</MenuItem>*/}
      {/*    <MenuItem value={'1A'}>1A</MenuItem>*/}
      {/*  </Select>*/}
      {/*</FormControl>*/}
      <br/>
      <Typography variant="h4" component="h4">{bracket} Bracket</Typography>

      <div>
        <img src={value} alt="bracket" />
      </div>

      {/*<SingleEliminationBracket*/}
      {/*  matches={value}*/}
      {/*  matchComponent={Match}*/}
      {/*  // svgWrapper={({ children, ...props }) => (*/}
      {/*  //   <SVGViewer width={1500} height={1500} {...props}>*/}
      {/*  //     {children}*/}
      {/*  //   </SVGViewer>*/}
      {/*  // )}*/}
      {/*/>*/}
    </div>
  );
}
